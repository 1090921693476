<script>
import api from "@/api";

export default {
  name: "Dashboard",
  data: function () {
    return {
      dStats: {},
      timeout: null,
    };
  },
  methods: {
    getName(user) {
      if (user.firstname || user.lastname) {
        return user.firstname + " " + user.lastname;
      } else if (user.username) {
        return user.username;
      }
      return "---";
    },
    getStats() {
      const url = "/DashboardStats/";
      api.Manager.axios
        .get(url)
        .then((response) => {
          this.dStats = response.data;
          this.timeout = setTimeout(this.getStats, 3000);
        })
        .catch((err) => {
          console.error(err);
          this.timeout = setTimeout(this.getStats, 3000);
        });
    },
    sendMessage() {
      alert("Not Yet Live!");
    },
  },
  beforeDestroy() {
    console.log(1);
  },
  created: function () {
    this.getStats();
  },
};
</script>
<template>
  <div id="Dashboard" class="container-fluid">
    <h4 class="d-none d-md-block">Dashboard</h4>

    <div class="row justify-content-center">
      <div class="col-12 col-sm-4 col-lg-4 stat text-center">
        <div class="inner">
          <div class="col-12">
            <div class="row justify-content-center">
              <div class="col text-center">
                <i class="text-warning fas fa-calendar fa-2x"></i>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col text-center">
                <span class="big">{{ dStats.no_of_bookings }}</span> <br />
                Bookings This Week
              </div>
            </div>
          </div>
        </div>
      </div>

      <router-link
        class="col-12 col-sm-4 col-lg-4 stat text-center"
        :to="{ name: 'BillingPaid' }"
      >
        <div class="inner">
          <div class="col-12">
            <div class="row justify-content-center">
              <div class="col text-center">
                <i class="text-warning fas fa-money-bill-alt fa-2x"></i>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col text-center">
                Ksh. <span class="big">{{ dStats.income_paid }}</span> /= <br />
                Income Paid This Week
              </div>
            </div>
          </div>
        </div>
      </router-link>

      <router-link
        class="col-12 col-sm-4 col-lg-4 stat text-center"
        :to="{ name: 'BillingUnpaid' }"
      >
        <div class="inner">
          <div class="col-12">
            <div class="row justify-content-center">
              <div class="col text-center">
                <i class="text-warning fas fa-dollar-sign fa-2x"></i>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col text-center">
                Ksh. <span class="big">{{ dStats.balances_pending }}</span> /=
                <br />
                Balances Pending This Week
              </div>
            </div>
          </div>
        </div>
      </router-link>

      <div class="col-12 col-sm-4 col-lg-4 stat text-center">
        <div class="inner">
          <div class="col-12">
            <div class="row justify-content-center">
              <div class="col text-center">
                <i class="text-success fas fa-calendar fa-2x"></i>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col text-center">
                <span class="big">{{ dStats.month_booking_count }}</span> <br />
                Bookings This Month
              </div>
            </div>
          </div>
        </div>
      </div>

      <router-link
        class="col-12 col-sm-4 col-lg-4 stat text-center"
        :to="{ name: 'BillingPaid' }"
      >
        <div class="inner">
          <div class="col-12">
            <div class="row justify-content-center">
              <div class="col text-center">
                <i class="text-success fas fa-money-bill-alt fa-2x"></i>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col text-center">
                Ksh. <span class="big">{{ dStats.month_income }}</span> /=
                <br />
                Income This Month
              </div>
            </div>
          </div>
        </div>
      </router-link>

      <router-link
        class="col-12 col-sm-4 col-lg-4 stat text-center"
        :to="{ name: 'BillingUnpaid' }"
      >
        <div class="inner">
          <div class="col-12">
            <div class="row justify-content-center">
              <div class="col text-center">
                <i class="text-success fas fa-dollar-sign fa-2x"></i>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col text-center">
                Ksh. <span class="big">{{ dStats.month_balances }}</span> /=
                <br />
                Week Balances Pending This Month
              </div>
            </div>
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<style lang="scss">
#Dashboard {
  text-overflow: hidden;
  background: #f6f6f6;
  padding-top: 10px;
  h4 {
    margin-bottom: 30px;
  }
  table {
    margin-top: 20px;
  }
  .stat {
    font-size: 15px;
    text-decoration: none;
    color: black;
    .inner {
      &:hover {
        background: radial-gradient(white, #f3f3f3);
      }
      padding-top: 30px;
      padding-bottom: 30px;
      margin-bottom: 10px;
      background: white;
      box-shadow: rgb(227, 228, 231) 0px 2px 0px 0px;
      border: 1px solid rgb(241, 241, 241);
      .big {
        font-weight: bold;
        font-size: 15px;
      }
    }
  }
}
</style>
